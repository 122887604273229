import { INewsCreateParams, ICommentCreateParams, IListQueryParam, IShareParams } from '@/typing'
import request from './request'
import { IPostParams } from '../typing'
// 前台-发布动态
const newsCreate = (data: INewsCreateParams) => {
  return request.post('/api/dynamic/publish', data)
}

// 前台-外部访问小组信息
const outerGroupDetail = (id: number) => {
  return request.get('/api/group/front/out/group?group_id=' + id)
}

// 前台-动态详情判断是否是小组成员
const isGroupMember = (id: number) => {
  return request.get('/api/dynamic/is_member?groupId=' + id)
}

// 前台-加入小组
const GroupJoin = (groupId: number, join_from: string) => {
  return request.get('/api/group/front/join_group?group_id=' + groupId + '&join_from=' + join_from)
}

// 前台-获取我加入的小组列表
const myGroupList = (num: number) => {
  return request.get(`/api/group/front/my_group_list?pageNum=${num}`)
}
// 动态列表
const trendList = (data: IPostParams) => {
  return request.get(`/api/dynamic/front/group/dynamics`, {
    params: data
  })
}

// 动态列表搜索
const searchTrendList = (data: IPostParams) => {
  return request.post(`/api/dynamic/front/dynamic/search`, data)
}

// 前台-获取更多小组列表
const moreGroupList = (num: number) => {
  return request.get(`/api/group/front/more_group_list?pageNum=${num}`)
}
// 前台-小组首页-动态广场
const newsDynamics = (num: number) => {
  return request.get(`/api/dynamic/front/index/dynamics?pageNum=${num}`)
}
// 前台-点击tap(zan,comment,system)查看小组消息
const groupMessage = (num: number, id: number, tab: string) => {
  return request.get(`/api/group/front/group/message?pageNum=${num}&group_id=${id}&tab=${tab}`)
}
// 前台-小组消息-tab提示数字
const groupMessageTab = (id: number) => {
  return request.get(`/api/group/front/group/message/tab?group_id=${id}`)
}

//前台-评论动态
const commentCreate = (data: ICommentCreateParams) => {
  return request.post('/api/dynamic/comment', data)
}

//前台-动态详情
const newsDetail = (newsId: number) => {
  return request.get('/api/dynamic/detail?dynamicId=' + newsId)
}

//前台-动态详情点赞列表
const zanList = (data: IListQueryParam) => {
  return request.get('/api/dynamic/detail/zans', { params: data })
}
// 前台-动态详情分享列表
const shareList = (data: IListQueryParam) => {
  return request.get('/api/dynamic/detail/shares', { params: data })
}

//前台-动态详情评论列表
const commentList = (data) => {
  return request.get('/api/dynamic/detail/comments', { params: data })
}
//前台-由评论消息列表跳转动态详情评论列表
const commentTransList = (data) => {
  return request.get('/api/dynamic/detail/trans/comments', { params: data })
}

// 小组详情
const groupDetail = (id: number | string) => {
  return request.get(`/api/group/front/group/detail?group_id=${id}`)
}
// 动态点赞/api/dynamic/support
const postZan = (data: IPostParams) => {
  return request.get(`/api/dynamic/support`, {
    params: data
  })
}
// 取消点赞 /api/dynamic/cancel_support
const cancelZan = (data: IPostParams) => {
  return request.get(`/api/dynamic/cancel_support`, {
    params: data
  })
}
// 小组 新消息个数/api/group/front/group/message/tab
const getNewsCount = (id: number | string) => {
  return request.get(`/api/group/front/group/message/tab?group_id=${id}`)
}

// 前台-删除动态
const newsDelete = (id: number) => {
  return request.get(`/api/dynamic/delete?id=${id}`)
}
// 退出小组 /api/group/front/quit_group
const quitGroup = (group_id: number | string) => {
  return request.get(`/api/group/front/quit_group?group_id=${group_id}`)
}

// 前台-分享动态
const share = (data: IShareParams) => {
  return request.get(`/api/dynamic/share`, {
    params: data
  })
}

// 前台-删除评论
const commentDelete = (id: number) => {
  return request.get(`/api/dynamic/front/comment/delete?id=${id}`)
}

//前台-小组详情-动态设为已读
const dynamicRead = (groupId: number, dynamicId: number) => {
  return request.get(`/api/group/front/group/dynamic/read?groupId=${groupId}&dynamicId=${dynamicId}`)
}
// 判断所选人员与小组的关系
const memberRole = (data) => {
  return request.post('/api/member/role', data)
}
// 前台-添加管理员
const addManager = (data) => {
  return request.post('/api/member/add_manager', data)
}
// 前台-小组管理员列表
const managersList = (groupId) => {
  return request.get(`/api/member/front/managers?groupId=${groupId}`)
}
// 前台-移出小组
const outGroup = (data) => {
  return request.post('/api/member/out_group', data)
}
// 前台-转为成员
const toMember = (data) => {
  return request.post('/api/member/to_member', data)
}
// 前台-判断用户是否有权限操作
const checkAuthority = (groupId) => {
  return request.get(`/api/group/front/check_authority?groupId=${groupId}`)
}
// 前台-置顶动态
const toTop = (groupId, dynamicId) => {
  return request.get(`/api/dynamic/front/to_top?groupId=${groupId}&dynamicId=${dynamicId}`)
}
// 前台-取消置顶动态
const cancelTop = (dynamicId) => {
  return request.get(`/api/dynamic/front/cancel_top?dynamicId=${dynamicId}`)
}
// 加入小组埋点
const joinGroupPoint = (data) => {
  return request.post('/api/bury/join_group_point', data)
}
// 退出小组埋点
const quitGroupPoint = (data) => {
  return request.post('/api/bury/quit_group_point', data)
}
// 动态交互埋点
const dynamicInteractionPoint = (data) => {
  return request.post('/api/bury/dynamic_interaction_point', data)
}
// 动态访问埋点
const dynamicViewPoint = (data) => {
  return request.post('/api/bury/dynamic_view_point', data)
}
// 小组首页访问埋点
const indexPoint = (data) => {
  return request.post('/api/bury/index_point', data)
}
// 前台-根据小组ID获取动态分类标签
const dynamicCategory = () => {
  return request.get(`/api/dynamicCategory/front/list`)
}
// 前台-根据小组ID获取评论分类标签
const commentCategory = (groupId) => {
  return request.get(`/api/commentCategory/front/list?groupId=${groupId}`)
}
// 前台-根据分类标签获取动态列表
const categoryDynamics = (data) => {
  return request.get(`/api/dynamic/front/category/dynamics`, {
    params: data
  })
}
// 验证token是否过期
const checkToken = (data) => {
  return request.get(`/api/user/ihaier/check_token`, {
    params: data
  })
}
// 获取ihaiertoken
const ihaierLogin = (data) => {
  return request.get(`/api/user/ihaier/login?code=${data.code}&client_id=${data.client_id}`)
}
// 广告位链接
const bannerGetLink = (data) => {
  return request.get(`/api/banner/getLnk?id=${data.id}&groupId=${data.groupId}`)
}

// 飞书鉴权
export function getFeishuSignPackage(data) {
  return request.get(`/api/feishu/signature?url=${data.url}`)
}

// 文化大讨论热度值
const cultureHot = () => {
  return request.get(`/api/group/front/culture/hot`)
}
//获取公告
const getAnnounceInfor = (id) => {
  return request.get(`/api/announcement/front/list?groupId=${id}`)
}

// 文化大讨论活力值
const cultureVitality = () => {
  return request.get(`/api/group/front/culture/vitality`)
}

// 文化大讨论参与度排行
const cultureActive = () => {
  return request.get(`/api/group/front/culture/active`)
}

// 帮助贴 - 确认解决
const taskSolve = (dynamicId) => {
  return request.get(`/api/dynamic/help/solve?dynamicId=${dynamicId}`)
}

// 帮助贴 - 放弃该帖
const taskGiveUp = (dynamicId) => {
  return request.get(`/api/dynamic/help/give_up?dynamicId=${dynamicId}`)
}

// 帮助贴 - 我要认领
const taskReceive = (dynamicId,mediaAccountId) => {
  return request.get(`/api/dynamic/help/receive?dynamicId=${dynamicId}&mediaAccountId=${mediaAccountId}`)
}

// 帮助贴 - 取消认领
const taskCancelReceive = (dynamicId,mediaAccountId) => {
  return request.get(`/api/dynamic/help/cancel_receive?dynamicId=${dynamicId}&mediaAccountId=${mediaAccountId}`)
}

// 帮助贴 - 变更负责人
const taskChangeHelper = (data) => {
  return request.post(`/api/dynamic/help/change_helper`, data)
}

// 帮助贴 - 获取帖子的状态变更列表
const activeStatusList = (dynamicId) => {
  return request.get(`/api/dynamic/help/statusList?dynamicId=${dynamicId}`)
}
// 获取话题列表
const getDynamicTopic = (data) => {
  return request.get('/api/dynamic/dynamicTopic/list', { params: data })
}
// 获取话题详情
const getTopicDetail = (data) => {
  return request.get('/api/dynamic/dynamicTopic/detail', { params: data })
}
// 话题解决进度
const getSolveProgress = (data) => {
  return request.get('/api/dynamic/help/solve_progress/page', { params: data })
}
// 指定话题解决进度--接口废弃了
const getSolveDetail = (data) => {
  if (data.topicId == null || data.topicId==undefined){
      return
  }
  return request.get('/api/dynamic/help/solve_progress', { params: data })
}

// 动态投票管理 - 取消投票
const cancelVote = (voteType, dynamicId) => {
  if (voteType === 1) return request.get(`/api/dynamic/vote/cancelVote?dynamicId=${dynamicId}`)
  if (voteType === 2) return request.get(`/api/topicVote/cancelVote?dynamicId=${dynamicId}`)
}

// 动态投票管理 - 投票
const doVote = (voteType, data) => {
  console.log(111111, voteType)
  if (voteType === 1) return request.post(`/api/dynamic/vote/doVote`, data)
  if (voteType === 2) return request.post(`/api/topicVote/doVote`, data)
}

// 动态投票管理 - 投票list
const getVoteList = (voteType) => {
  return request.get(`/api/dynamic/vote/options?voteType=${voteType}`)
}
// 帮助帖转化
const transferStatus = (data) => {
  return request.post(`/api/dynamic/transfer`, data)
}
// 修改动态话题
const changeTopic = (data) => {
  return request.post(`/api/dynamic/change_topic`, data)
}
// 获取动态关联话题
const queryTopic = (data) => {
  return request.get(`/api/dynamic/query_topic`, { params: data })
}
// 隐藏动态
const topicVisible = (data) => {
  return request.get(`/api/dynamic/front/visible`, { params: data })
}
// 获取时间
const getTimes = (data) => {
  return request.get(`/api/announcement/front/pop_up`, { params: data })
}
// 获取所有话题
const getAllTopics = (data) => {
  return request.get(`/api/dynamic/dynamicTopic/query_all`, { params: data })
}
// 话题年解决进度
const yearSolveProgress = (data) => {
  return request.get(`/api/dynamic/label/gantt/solve_progress`, { params: data })
}
const yearSolveProgressByYear = (data) => {
  return request.get(`/api/dynamic/label/gantt/solve_progress_by_year`, { params: data })
}

// 餐厅列表选择页面
const getCanteenList = () => {
  return request.get(`/api/group/front/refectory_list`)
}

// 举报动态
const dynamicReport = (data) => {
  return request.post('/api/accusation/submit', data)
}
// 举报评论-当前接口已弃用，使用上面的接口
const dynamicCommentReport = (data) => {
  return request.get('/api/accusation/comment', { params: data })
}

// 举报原因列表
const reportResons = () => {
  return request.post('/api/accusation/reason/list')
}

// 判断用户积分余额
const getScore = () => {
  return request.get('/api/accusation/judge/score')
}

// 评论点赞/点踩
const dynamicCommentSupport = (data) => {
  return request.get('/api/dynamic/comment/support', { params: data })
}

// 取消评论点赞/点踩
const dynamicCommentCancelSupport = (data) => {
  return request.get('/api/dynamic/comment/cancel_support', { params: data })
}
// 取消评论点赞/点踩
const dynamicMove = (data) => {
  return request.get('/api/dynamic/front/move', { params: data })
}
// 餐厅列表
const getCanteenList4Search = () => {
  return request.get('/api/group/front/refectoryArea/refectorList')
}
// 获取广场小组列表
const getSquareList = (data) => {
  return request.get('/api/group/front/refectoryArea/refectorList', { params: data })
}
// 获取广场小组详情
const getSquareDetailList = (data) => {
  return request.get('/api/dynamic/front/index/dynamics', { params: data })
}
// 设置精选帖
const setChoiceNess = (data) => {
  return request.post(`/api/dynamic/choice_ness`, data)
}
///api/group/front/area/list 专区列表
const getAreaList = () => {
  return request.get('/api/group/front/area/list')
}

// 评价维度表
const getJudgeOptions = (data) => {
  return request.get('/api/group/judge/options', { params: data })
}

// 评价维度表
const getJudgeDepartment = () => {
  return request.get('/api/group/judge/departments')
}
//添加标签
const addLabel = (data) => {
  return request.post('/api/dynamic/label/add', data)
}

const queryLabel = (data) => {
  return request.get(' /api/dynamic/label/bind_list', { params: data })
}
const getAllLabel = () => {
  return request.get('/api/dynamic/label/query_all')
}

const bindLabel = (data) => {
  return request.post('/api/dynamic/label/bind', data)
}

const getLabelDetail = (data) => {
  return request.get('/api/dynamic/label/detail', { params: data })
}

//获取当前登录用户角色
const getUserRole = () => {
  return request.get('/api/user/get_role')
}

//获取用户消息配置 api/user/message/setting
const getMsgSetting = () => {
  return request.get('/api/user/message/setting')
}

//获取用户消息配置 api/user/message/setting
const modifyMsgSetting = (id) => {
  return request.put(`/api/user/message/setting/${id}/status`)
}

// 获取标签分类
const getLabelCate = () => {
  return request.get('/api/dynamic/label/backend/first_level_label')
}
const getLabelSbuCate = (data: any) => {
  return request.get('/api/dynamic/label/backend/second_level_label', { params: data })
}
// 获取小助手列表
const assistantList = (data) => {
  return request.get(`/api/group/assistant/list`, { params: data })
}
// 移出小助手
const assistantDel = (data) => {
  return request.post(`/api/group/assistant/remove`, data)
}
// 添加小助手
const assistantAdd = (data) => {
  return request.post(`/api/group/assistant/add`, data)
}

/**
 *
 * 目前的guideType：
 * 首页个人空间指引：'psi'
 * 评论指引：'cmti'
 * 帖子指引：'invi'
 * 帖子详情问题解决确认指引：psci:problem-solving-confirm-intro
 * 帮助贴系统自动确认后申诉：psbsai:problem-solved-by-system-appeal-intro
 * 消息设置提示：msi:message-setting-intro
 * 新评论使用实名指引：'ncri':new-comment-realname-intro
 * 发帖使用实名指引： 'cdri':create-dynamic-realname-intro
 * 问题解决进度指引： 'prsi':problemsolvingintro
 * 新增话题指引、 'atpci':addtopicintro
 * 新增话题按钮指引、 'atpcti':addtopictipintro
 * 后台管理、 'pai':personadminintro
 * 
 */

// 新手指引-记录已读
const addIntroLog = (data) => {
  return request.get(`/api/user/add_guide_log`, { params: data })
}
// 新手指引-查询是否已读
const checkIntroLog = (data) => {
  return request.get(`/api/user/check_guide_log`, { params: data })
}

// 投票
const dynamicVote = (data) => {
  return request.post(`/api/dynamic/vote/submit`, data)
}
// 匿名用户卡片
const nickCard = (data) => {
  return request.get('/api/user/nickname/card', { params: data })
}

// 帮助贴-暂未解决
const noSolvePost = (data) => {
  return request.get('/api/dynamic/help/no_solve', { params: data })
}

// 首页话题热度排行
const indexHeatRank = () => {
  return request.get('/api/dynamic/dynamicTopic/index_heat_rank')
}

// 更多话题热度排行
const moreHeatRank = () => {
  return request.get('/api/dynamic/dynamicTopic/more_heat_rank')
}
//帮助贴帖子用户申诉
const appeal = (data) => {
  return request.post('/api/dynamic/appeal', data)
}
//话题热榜 埋点
const topicHotRankPoint = (data) => {
  return request.post('/api/bury/topic_hot_rank_point', data)
}
//根据用户Id获取openid
const getOpenIdByUserId = (data) => {
  return request.get('/api/user/getOpenIdByUserId', { params: data })
}

//会话列表(轮询)
const getSessionList = (data) => {
  return request.get('/api/message/session/list', { params: data })
}

//建立会话
const sessionCreate = (data) => {
  return request.post('/api/message/session/create', data)
}
// 发送消息
const messageSend = (data) => {
  return request.post('/api/message/sessionMessage/send', data)
}
// 获取历史消息
const getHistoryMessList = (data) => {
  return request.get('/api/message/sessionMessage/histories', { params: data })
}
// 是否首次进入回话
const firstJoin = (data) => {
  return request.get('/api/message/session/firstJoin', { params: data })
}
// 设置消息为已读
const messageView = (data) => {
  return request.get('/api/message/sessionMessage/view', { params: data })
}
// 查询双方是否建立过会话用工号
// 设置消息为已读
const checkSessionByUser = (data) => {
  return request.get('/api/message/session/query_by_user', { params: data })
}
// 设置消息为已读--other
// const checkSessionDynamic = (data) => {
//   return request.get('/api/message/session/query_by_dynamic', { params: data })
// }
// 查询双方是否建立过会话-用sessionid
// 设置消息为已读
const checkSessionById = (data) => {
  return request.get('/api/message/session/query_by_id', { params: data })
}
// 判断会话身份
const checkSessionRole = (data) => {
  return request.get('/api/message/session/role', { params: data })
}

// 在线状态检测
const checkSessionOnline = (data) => {
  return request.get('/api/message/session/online', { params: data })
}
// 从消息机器人跳到会话页和会话列表页后更新卡片状态
const updateStatausFromMsgCard = () => {
  return request.post('/api/message/card/update_status')
}
// 获取未读消息数量
const otherNoReadTotal = (data) => {
  return request.get('/api/message/sessionMessage/otherNoReadTotal', { params: data })
}
// 检查指定用户是否在线
const online_check = (data) => {
  return request.get('/api/message/session/online_check', { params: data })
}

// 审核帖子
const auditNews = (data: any) => {
  return request.post('/api/dynamic/audit_dynamic', data)
}

// 审核评论
const auditComment = (data: any) => {
  return request.post('/api/dynamic/audit_comment', data)
}
// 获取用户基本信息
const getUserInfoById = (data: any) => {
  return request.get('/api/user/userinfo/by_id', { params: data })
}
// 创建匿名会话埋点
const creatChatPoint = (data: any) => {
  return request.post('/api/bury/create_anonymous_chat_point', data)
}
// 发送匿名会话埋点
const sendChatPoint = (data: any) => {
  return request.post('/api/bury/anonymous_chat_send_point', data)
}
// 检测是否首次进入会话
const checkFirstJoin = (data: any) => {
  return request.get('/api/message/session/firstJoin', { params: data })
}
// 用户下线
const offline = (data: any) => {
  return request.get('/api/message/session/offline')
}
const updateReadSpeed = (data: any) => {
  return request.post('/api/dynamic/handbook/update_speed', data)
}
// 用户下线
const getReadProgress = () => {
  return request.get('/api/dynamic/handbook/current_speed')
}
// 获取学习时长
const getReadTime = (data: any) => {
  return request.post('/api/dynamic/handbook/add_learn_time', data)
}
//获取开屏广告
const getOpenAd = (id) => {
  return request.get('/api/banner/openAd?groupId=' + id)
}
// 获取推荐话题
const getRecommends = (data) => {
  return request.get('/api/dynamic/dynamicTopic/recommends?groupId=' + data)
}

//获取首页热议话题
const getIndexHot = (data) => {
  return request.get('/api/dynamic/dynamicTopic/index/hotTalk')
}
//手册当前进度-接口废弃了
const getHandbookSpeed = () => {
  return request.get('/api/dynamic/handbook/current_speed')
}
//记录开始学习时间
const recordStartLearnTime = (data) => {
  return request.get('/api/dynamic/handbook/start_learn_time', { params: data })
}
//记录开始学习时间
const onlineHeart = (data) => {
  return request.get('/api/dynamic/handbook/online_heart', { params: data })
}
//获取手册详情
const handbook_detail = (data) => {
  return request.get('/api/handbook/detail', { params: data })
}
//存储用户浏览位置
const handbook_set_browse_location = (data) => {
  return request.get('/api/handbook/set_browse_location', { params: data })
}
//获取用户浏览位置
const handbook_get_browse_location = (data) => {
  return request.get('/api/handbook/get_browse_location', { params: data })
}

// 用户新增话题
const addTopic = (data: any) => {
  return request.post('/api/dynamic/dynamicTopic/front/add', data)
}

const getBannerXuehai = () => {
  return request.get('/xuehai/h5/v1/home/home_banner?industry_id=0')
}
// 获取学海热榜
const getHotXuehai = () => {
  return request.post('/xuehai/h5/v1/home/hot_list', {"page": 1, "pagesize": 10, "day": 30})
}

// 首页话题列表
const homeTopic = (data: any) => {
  return request.get('/api/dynamic/dynamicTopic/home_topic', {params:data})
}
//手册页面埋点
const handbook_view_send_point = (data:any) => {
  return request.post('/api/bury/handbook_view_send_point', data)
}
//手册评论埋点
const handbook_comment_point = (data:any) => {
  return request.post('/api/bury/handbook_comment_point', data)
}
// 分享上报学海积分
const shareDumpScore = (data: any) => {
  return request.get('/api/dynamic/share/dumpScore', {params:data})
}
// 认领人列表
const log_helpers = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/helpers', {params:data})
}
// 认领人详情
const helperPage = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/helperPage', {params:data})
}
// 认领人详情列表
const helperPage_list = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/helperPage/list', {params:data})
}
// 认领人页面埋点
const helper_page_view_point = (data: any) => {
  return request.post('/api/bury/helper_page_view_point', data)
}
// 讨论获取小组接口
const queryDiscuss = (data: any) => {
  return request.get('/api/group/front/discuss', {params:data})
}
// 获取模块数据
const home_module_list = (data: any) => {
  return request.post('/xuehai/h5/v1/section/home_module_list', data)
}
// 讨论话题和小组关联的帖子
const auto_relate_dynamics = (data: any) => {
  return request.get('/api/openApi/xuehai/channel/auto_relate_dynamics', {params:data})
}
// 讨论话题和小组关联的帖子
const zan_dynamic = (data: any) => {
  return request.get('/api/openApi/zan_dynamic', {params:data})
}
// 讨论话题和小组关联的帖子
const cancel_zan_dynamic = (data: any) => {
  return request.get('/api/openApi/cancel_zan_dynamic', {params:data})
}
// 获取实时点赞
const real_time_zan = (data: any) => {
  return request.post('/api/openApi/group/real_time_zan', data)
}
// 发布到学海接口
const xuehai_create_article = (data: any) => {
  return request.post('/xuehai/h5/v1/resource/create_article_from_group', data)
}
// 获取前端跳转融媒云
const getFontUrl = (data: any) => {
  return request.get('/api/third/getFontUrl', {params:data})
}
// 获取热帖列表
const hot_dynamic_list = (data: any) => {
  return request.get('/api/openApi/xuehai/hot_dynamic_list', {params:data})
}
// 用户个人问题主页-解决状态占比
const person_resolveRate = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/person/resolveRate', {params:data})
}
// 用户个人问题主页-帖子列表
const person_dynamics = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/person/dynamics', {params:data})
}
// 用户个人问题主页-小助手列表
const person_assistants = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/person/assistants', {params:data})
}
// 用户个人问题主页-小助手搜索
const person_assistants_search = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/person/assistants_search', {params:data})
}
// 用户个人问题主页-小助手@
const person_call_assistant = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/person/call_assistant', {params:data})
}
// 用户个人问题主页-小助手@
const first_sort_id = (data: any) => {
  return request.get('/api/group/front/first_sort_id', {params:data})
}
//获取DCM组织树
const dcmTree = () => {
  return request.get("/api/dcm/v1/dcmTree")
}
// 申请创建专区
const create_group = (data: any) => {
  return request.post('/api/group/front/create_group', data)
}
// 编辑专区
const retry_create_group = (data: any) => {
  return request.post('/api/group/front/retry_create_group', data)
}
//获取用户创建专区审核结果通知
const audit_result = () => {
  return request.get("/api/group/front/audit/result")
}
// 后台-获取小组详情
const groupBackDetail = (data: any) => {
  return request.get(`/api/group/back/detail?id=${data}`, data);
};
// 
// 权限判断
const check_is_manage = () => {
  return request.get(`/api/tenant_manager/is_manager`);
};
// 检查用户是否
const check_has_group = (data: any) => {
  return request.post('/api/group/back/user/group_list', data)
}
// 小助手申诉
const front_appeal = (data: any) => {
  return request.post('/api/helperAppeal/front/appeal', data)
}
// 小助手融媒号列表
const xuehai_assistant_list = (data: any) => {
  return request.get('/backend/hi/memberMedia/assistant_list', {params: data})
}
// 小助手排行列表
const board_assistant_rank = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/board/assistant/rank', {params: data})
}
// 管理员数据总览
const board_manager_data = (data: any) => {
  return request.get('/api/super/boardManager/data', {params: data})
}
// 管理员数据总览top
const assistant_topData = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/board/assistant/topData', {params: data})
}
// 核心数据总览top
const coreDataView = (data: any) => {
  return request.get('/api/super/boardManager/coreDataView', {params: data})
}
// 数据趋势折线图
const dataTrendBoard = (data: any) => {
  return request.get('/api/super/boardManager/dataTrendBoard', {params: data})
}
// 内容分级
const dynamicCategoryRatio = (data: any) => {
  return request.post('/api/super/boardManager/dynamicCategoryRatio', data)
}
// 获取进入的uvpv
const todayPvUv = (data: any) => {
  return request.get('/api/super/boardManager/todayPvUv', {params: data})
}
// 获取小助手的认领贴的数据
const waitHelpDynamics = (data: any) => {
  return request.get('/api/super/boardManager/waitHelpDynamics', {params: data})
}
// 获取管理者的帖子公告
const assistant_tabCount = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/board/assistant/tabCount', {params: data})
}
// 数据分析-帖子列表
const cultureDynamicList = (data: any) => {
  return request.post('/api/data/dynamic/search', data)
}
// 获取所有小组列表
const cultureGroupList = (data: any) => {
  return request.post("/api/group/back/all", data);
}
const exportDynamic = (data: any) => {
  return request.get('/api/data/dynamic/export/analysis_dynamics', {
    params: data,
    responseType: "blob",
  })
}
// 获取发言态度
const drop_list = (data: any) => {
  return request.get('/api/dynamicCategory/drop/list', {params: data})
}

//数据看板-小助手身份-首页看板
const assistantDataBoard = (data: any) => {
  return request.get('/api/dynamic/dynamicHelpLog/board/assistant/indexBoard', {params: data})
}

//根据认领状态查帖子列表
const searchByClaimStatus = (data: any) => {
  return request.get('/api/dynamic/searchByClaimStatus', {params: data})
}
// 发言态度数据趋势折线图
const attitudeTrend = (data: any) => {
  return request.get('/api/dynamic/analysis/attitudeTrend', {params: data})
}
// 认领人申请已解决
const applyClose = (data: any) => {
  return request.post("/api/dynamic/dynamicHelpLog/applyClose", data);
}
// 关键词领域占比
const key_domainRatio = (data: any) => {
  return request.get('/api/dynamic/analysis/keyword/domainRatio', {params: data})
}
// 领域发帖明细
const domainOrPlatformDetail = (data: any) => {
  return request.get('/api/dynamic/analysis/keyword/domainOrPlatformDetail', {params: data})
}
// 领域族群
const zqPublishDetail = (data: any) => {
  return request.get('/api/dynamic/analysis/keyword/zqPublishDetail', {params: data})
}
// 领域赛道
const trackPublishDetail = (data: any) => {
  return request.get('/api/dynamic/analysis/keyword/trackPublishDetail', {params: data})
}

// 当前账号下的对当前用户可见的学海文章+帖子
const getUserArticles = (data) => {
  return request.post('/xuehai/h5/v1/user/user_articles_by_chat_user', data)
}

export default {
  newsCreate,
  outerGroupDetail,
  isGroupMember,
  GroupJoin,
  commentCreate,
  myGroupList,
  newsDetail,
  zanList,
  shareList,
  commentList,
  trendList,
  searchTrendList,
  moreGroupList,
  groupDetail,
  postZan,
  cancelZan,
  newsDelete,
  getNewsCount,
  groupMessage,
  groupMessageTab,
  quitGroup,
  share,
  commentTransList,
  commentDelete,
  newsDynamics,
  dynamicRead,
  memberRole,
  addManager,
  managersList,
  outGroup,
  toMember,
  checkAuthority,
  toTop,
  cancelTop,
  joinGroupPoint,
  quitGroupPoint,
  dynamicInteractionPoint,
  dynamicViewPoint,
  indexPoint,
  dynamicCategory,
  commentCategory,
  categoryDynamics,
  checkToken,
  ihaierLogin,
  bannerGetLink,
  getFeishuSignPackage,
  cultureHot,
  getAnnounceInfor,
  cultureActive,
  cultureVitality,
  taskSolve,
  taskGiveUp,
  taskReceive,
  taskCancelReceive,
  taskChangeHelper,
  activeStatusList,
  getDynamicTopic,
  getTopicDetail,
  getSolveProgress,
  getSolveDetail,
  doVote,
  cancelVote,
  getVoteList,
  transferStatus,
  changeTopic,
  queryTopic,
  topicVisible,
  getTimes,
  getAllTopics,
  yearSolveProgress,
  yearSolveProgressByYear,
  getCanteenList,
  dynamicReport,
  dynamicCommentReport,
  dynamicCommentSupport,
  dynamicCommentCancelSupport,
  dynamicMove,
  getCanteenList4Search,
  getAreaList,
  getSquareList,
  getSquareDetailList,
  setChoiceNess,
  getJudgeOptions,
  getJudgeDepartment,
  getMsgSetting,
  modifyMsgSetting,
  addLabel,
  queryLabel,
  getAllLabel,
  bindLabel,
  getLabelDetail,
  getUserRole,
  getLabelCate,
  getLabelSbuCate,
  assistantList,
  assistantDel,
  assistantAdd,
  addIntroLog,
  checkIntroLog,
  nickCard,
  noSolvePost,
  dynamicVote,
  indexHeatRank,
  moreHeatRank,
  appeal,
  auditNews,
  auditComment,
  topicHotRankPoint,
  getOpenIdByUserId,
  getSessionList,
  sessionCreate,
  messageSend,
  getHistoryMessList,
  firstJoin,
  messageView,
  checkSessionByUser,
  checkSessionById,
  checkSessionRole,
  checkSessionOnline,
  updateStatausFromMsgCard,
  otherNoReadTotal,
  online_check,
  getUserInfoById,
  creatChatPoint,
  sendChatPoint,
  checkFirstJoin,
  offline,
  updateReadSpeed,
  getReadProgress,
  getReadTime,
  getOpenAd,
  getRecommends,
  getHandbookSpeed,
  recordStartLearnTime,
  onlineHeart,
  handbook_detail,
  handbook_set_browse_location,
  handbook_get_browse_location,
  addTopic,
  getBannerXuehai,
  getHotXuehai,
  homeTopic,
  handbook_view_send_point,
  handbook_comment_point,
  shareDumpScore,
  log_helpers,
  helperPage,
  helperPage_list,
  helper_page_view_point,
  queryDiscuss,
  home_module_list,
  auto_relate_dynamics,
  zan_dynamic,
  cancel_zan_dynamic,
  real_time_zan,
  xuehai_create_article,
  getFontUrl,
  hot_dynamic_list,
  person_resolveRate,
  person_dynamics,
  person_assistants,
  person_assistants_search,
  person_call_assistant,
  first_sort_id,
  dcmTree,
  create_group,
  audit_result,
  groupBackDetail,
  check_is_manage,
  retry_create_group,
  check_has_group,
  front_appeal,
  xuehai_assistant_list,
  board_assistant_rank,
  board_manager_data,
  assistant_topData,
  coreDataView,
  dataTrendBoard,
  dynamicCategoryRatio,
  todayPvUv,
  waitHelpDynamics,
  assistant_tabCount,
  cultureDynamicList,
  cultureGroupList,
  exportDynamic,
  drop_list,
  assistantDataBoard,
  searchByClaimStatus,
  reportResons,
  getScore,
  getIndexHot,
  attitudeTrend,
  applyClose,
  key_domainRatio,
  domainOrPlatformDetail,
  zqPublishDetail,
  trackPublishDetail,
  // checkSessionDynamic,
  getUserArticles
}
